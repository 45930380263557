import { LoremIpsum } from "lorem-ipsum";
import { randBool, randInt } from "./random";

const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});

const makePost = () => {
  const post = {
    datePublished: ["2020-01-01", "at", "9:57AM"],
    words: lorem.generateParagraphs(1).split(" "),
    image: false,
    faves: randInt(50) * Math.ceil(1 - Math.sin(randInt(50))),
  };
  const hasImage = randBool();
  if (hasImage) {
    const imageVariantConstant = randInt(128);
    const imageDimensions = {
      height: 64 + imageVariantConstant,
      width: 36 + imageVariantConstant,
    };
    post["image"] = {
      ...imageDimensions,
      url: `https://source.unsplash.com/random/${imageDimensions.height}x${imageDimensions.width}`,
    };
  }
  return post;
};

const makeAuthor = () => {
  // Make a fake author name.
  return {
    name: [lorem.generateWords(2), lorem.generateWords(3)],
  };
};

export { makeAuthor, makePost, lorem };
