import * as React from "react";
import { useState } from "react";
import { Text } from "core";
import ReactDOM from "react-dom";
import InfiniteScroll from "react-infinite-scroller";

import { FiBell, FiBellOff } from "react-icons/fi";
import { Feed, ButtonBar, NavBar, Loader } from "core";
import Post from "./Post";
import { randInt, randBool, makePost, makeAuthor, lorem } from "./helpers";
import { ToastContainer } from "react-toastify";
import { GrowthHacker } from "./GrowthHacker";

import "react-toastify/dist/ReactToastify.css";

const makePosts = (num: Number) => {
  return Array(num)
    .fill(null)
    .map((_) => makePost());
};

const App = () => {
  const [feedMode, setFeedMode] = useState("All Posts");
  const [posts, setPosts] = useState(makePosts(16));
  const [notifications, setNotifications] = useState(true);
  const postList = posts.map((post, index) => (
    <Post post={post} author={makeAuthor()} key={index} />
  ));
  const appendMorePosts = () => {
    setTimeout(
      () =>
        setPosts([...posts, ...makePosts(Math.ceil(Math.random() * 32) + 8)]),
      1000
    );
  };
  const prependMorePosts = () => {
    setTimeout(
      () =>
        setPosts([...makePosts(Math.ceil(Math.random() * 32) + 8), ...posts]),
      1000
    );
  };
  const loseScrollPosition = () => {
    // Lucky you! Your scroll position is gone. Oh well.
    console.log(
      "Lucky you! Looks like you lost your scroll position. That sucks."
    );
    setTimeout(() => setPosts(makePosts(randInt(32) + 8)), 300);
  };

  return (
    <>
      <NavBar
        buttonHandler={() => setNotifications(!notifications)}
        buttonContent={notifications ? <FiBell /> : <FiBellOff />}
      >
        faverr.club
      </NavBar>
      <Feed>
        <h1>Your FaveFeed</h1>
        <ButtonBar>
          <button
            onClick={() => {
              setFeedMode("All Posts");
              loseScrollPosition();
            }}
          >
            All
          </button>
          <button
            onClick={() => {
              setFeedMode("Hot");
              loseScrollPosition();
            }}
          >
            Hot
          </button>
          <button
            onClick={() => {
              setFeedMode("Top");
              loseScrollPosition();
            }}
          >
            Top
          </button>
          <button
            onClick={() => {
              setFeedMode("Engaging");
              loseScrollPosition();
            }}
          >
            Engaging
          </button>
        </ButtonBar>
        <h2>{feedMode}</h2>
        <InfiniteScroll
          loadMore={appendMorePosts}
          hasMore={true}
          loader={<Loader />}
        >
          {postList}
        </InfiniteScroll>
        <ToastContainer position="bottom-right" />
        {notifications && (
          <GrowthHacker
            appendMorePosts={appendMorePosts}
            prependMorePosts={prependMorePosts}
            loseScrollPosition={loseScrollPosition}
          />
        )}
      </Feed>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById("__root"));
