// Post component.

import React, { useState } from "react";

import { Box, Text, Paragraph, Title, Image, Icons } from "core";
import { FiHeart, FiMessageSquare } from "react-icons/fi";
import { toast, Zoom } from "react-toastify";

const defaultInteractionToast = {
  autoClose: 5000,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  transition: Zoom,
  style: { borderRadius: "0px", color: "gray" },
};

const onFaveHandler = (isFaved, setFave) => () => {
  toast(isFaved ? "Removed from your faves." : "Added to your faves.", {
    ...defaultInteractionToast,
    toastId: `faves-${isFaved}`,
  });
  setFave(!isFaved);
};

const onRefaveHandler = (isOnFaveFeed, setFaveFeed) => () => {
  toast(
    isOnFaveFeed ? `Removed from your FaveFeed` : `Refaved to your FaveFeed`,
    { ...defaultInteractionToast }
  );
  setFaveFeed(!isOnFaveFeed);
};

const Post = ({ post, author }) => {
  const [isFaved, setFaved] = useState(false);
  const [onFaveFeed, setFaveFeed] = useState(false);
  const words = post.words.map((word, index) => (
    <Text key={index}>{word}</Text>
  ));
  const authorName = author.name.map((word, index) => (
    <Title key={index}>{word}</Title>
  ));
  const datePublished = post.datePublished.map((word, index) => (
    <Text key={index}>{word}</Text>
  ));
  const faves = post.faves + (isFaved ? 1 : 0);
  return (
    <Box>
      <Paragraph>{authorName}</Paragraph>
      <Paragraph>{datePublished}</Paragraph>
      {post.image && <Image src={post.image.url} />}
      <Paragraph>{words}</Paragraph>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "0.5rem",
          color: "gray",
        }}
      >
        <p style={{ margin: 0 }}>
          {faves} {faves == 1 ? `person` : `people`} faved this
        </p>
        <div>
          <Icons onClick={onFaveHandler(isFaved, setFaved)} color="#F44336">
            <FiHeart />
          </Icons>
          <Icons
            onClick={onRefaveHandler(onFaveFeed, setFaveFeed)}
            color="#2196F3"
          >
            <FiMessageSquare />
          </Icons>
        </div>
      </div>
    </Box>
  );
};

export default Post;
