// Component to do like, growth hacking. Engagement number go UP

import React, { useEffect } from "react";
import { toast, Zoom } from "react-toastify";
import { Title } from "core";

import { randInt } from "../helpers";

// Texts we use to hack growth or whatever.
const toastOptions = [
  <p>
    <Title>Absolutely </Title>
    <Title>No One </Title> faved a new post.
  </p>,
  <p>
    <Title>Attractive </Title>
    <Title>Person </Title>uploaded a new picture.
  </p>,
  <p>
    <Title>YourEx </Title>
    <Title>Girlfriend </Title>is in a new relationship.
  </p>,
  <p>{`We've updated our privacy policy. Pray we don't update it further.`}</p>,
  <p>
    <Title>A Relative </Title>posted photos to the album <Title>Some </Title>
    <Title>Babyshower</Title>
  </p>,
  <p>You have 1 unread notification.</p>,
  `Please consent to the new Terms and Conditions.`,
  `We take disinformation seriously, when we don't profit off it.`,
  `There's no reason to leave... just enjoy the scrolling.`,
  `You have 2 new friend requests`,
  `Someone just refaved your latest fave!`,
  `You have 4 new re-faves.`,
  <p>
    <Title>A robot </Title>just faved your latest fave.
  </p>,
  `The algorithm needs faves. The algorithm... loves faves.`,
  `Please upload your contacts list.`,
  `Help, my product manager won't let me leave until engagement goes up.`,
  <p>
    <Title>Your relative </Title>refaved your photo
  </p>,
  `Vote for faverr.club on ProductHunt!`,
  `Upvote faverr.club on HackerNews!`,
  `Keep scrolling! It's always free.`,
  `You haven't refaved in awhile... You're not visiting other websites are you?`,
  <p>
    <Title>First </Title>
    <Title>Name </Title>, try faving more content!
  </p>,
  `Tell your friends!`,
  `Keep exploring your FaveFeed.`,
  `Did you see the Engaging section lately?`,
];

const GrowthHacker = ({ loseScrollPosition }) => {
  // Growth hacking growth notifications.
  //
  // Just like fb taught us.
  useEffect(() => {
    const timeout = setInterval(() => {
      if (randInt(10) > 7) {
        toast(toastOptions[randInt(toastOptions.length) - 1], {
          autoClose: 5000,
          pauseOnFocusLoss: false,
          hideProgressBar: true,
          transition: Zoom,
          style: { borderRadius: "0px", color: "gray" },
        });
      }
    }, 5000);
    return () => clearInterval(timeout);
  }, []);
  // New posts thing.
  //
  // Seriously who uses these? like, why is this a feature on websites?
  // I know this is satire but it feels shitty to put in here.
  useEffect(() => {
    const timeout = setInterval(() => {
      toast.info(
        <p style={{ textAlign: "center", margin: 0 }}>See New Posts</p>,
        {
          autoClose: false,
          onClose: () => {
            loseScrollPosition();
            window.scrollTo(0, 0);
          },
          position: "top-center",
          closeButton: false,
          toastId: "growth-hack-scroller",
          style: {
            borderRadius: "0px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#42A5F5",
            padding: 0,
            minHeight: 0,
          },
        }
      );
    }, 60000);
    return () => clearInterval(timeout);
  }, []);
  return null;
};

export { GrowthHacker };
